import clsx from 'clsx';

import ListItem from './list-item/list-item';
import styles from './list.module.scss';
import type { ListType } from './types';

const List: ListType = ({ children, className, header, ...rest }) => {
  return (
    <div className={styles.listContainer}>
      {header && <div className={styles.listHeader}>{header}</div>}
      <div className={clsx(styles.list, className)} {...rest}>
        {children}
      </div>
    </div>
  );
};

List.Item = ListItem;

export default List;
