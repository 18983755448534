import { useEffect, useState, VFC } from 'react';

import { getUpdatesList } from 'src/api/updates-api/updates-api';
import { UpdatesList } from 'src/features';
import { Empty, Loader, Modal } from 'src/shared/components';
import { postCloseMessage } from 'src/shared/lib/message';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { updatesActions, updatesSelectors } from 'src/store/updates';

import styles from './updates-list.module.scss';

const UpdatesListPage: VFC = () => {
  const dispatch = useAppDispatch();

  const updates = useAppSelector(updatesSelectors.getUpdates);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!updates) {
      setIsLoading(true);

      getUpdatesList()
        .then(({ data, status }) => {
          if (status === 200 && data) {
            dispatch(
              updatesActions.setUpdates({
                allUpdates: data.all_updates.sort(
                  (first, second) =>
                    new Date(second.publication_date).getTime() - new Date(first.publication_date).getTime()
                ),
                notWatchedUpdates: data.not_watched_updates.sort(
                  (first, second) =>
                    new Date(second.publication_date).getTime() - new Date(first.publication_date).getTime()
                ),
              })
            );
          }
        })
        .catch(() => {
          // TODO: handle error
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, updates]);

  return (
    <main className={styles.updates}>
      <Modal className={styles.updatesModal} header={<h2>Обновления</h2>} handleClose={postCloseMessage}>
        {isLoading && <Loader />}
        {!updates && !isLoading && <Empty />}

        {updates && !isLoading && <UpdatesList updates={updates} />}
      </Modal>
    </main>
  );
};

export default UpdatesListPage;
