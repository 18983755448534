import type { VFC } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { ThemeProvider, StorageProvider } from 'src/features';
import { UpdatesListPage, UpdateViewPage } from 'src/pages';
import { ROUTES } from 'src/shared/constants';

const Router: VFC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <StorageProvider>
          <Routes>
            <Route path={ROUTES.root} element={<UpdatesListPage />} />
            <Route path={ROUTES.update} element={<UpdateViewPage />} />
            <Route path="*" element={<Navigate to={ROUTES.root} />} />
          </Routes>
        </StorageProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default Router;
