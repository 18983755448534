import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getItemFromStorage, setItemToStorage } from 'src/shared/lib/storage';

import { DEFAULT_THEME, THEME_STORAGE_KEY } from './constants';
import themeContext from './theme-context';
import { Theme } from './types';

const ThemeProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState<Theme>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const themeFromParams = searchParams.get(THEME_STORAGE_KEY);

    if (themeFromParams) {
      if (themeFromParams in Theme) {
        setTheme(themeFromParams as Theme);
        setItemToStorage(THEME_STORAGE_KEY, themeFromParams);
      } else {
        setTheme(DEFAULT_THEME);
        setItemToStorage(THEME_STORAGE_KEY, DEFAULT_THEME);
      }
    } else {
      const themeFromStorage = getItemFromStorage(THEME_STORAGE_KEY);
      setTheme(themeFromStorage || DEFAULT_THEME);
    }
  }, [searchParams, setTheme]);

  return (
    <themeContext.Provider value={{ theme: theme || DEFAULT_THEME, setTheme }}>
      {theme && <div className={clsx(theme === Theme.dark && 'theme-dark')}>{children}</div>}
    </themeContext.Provider>
  );
};

export default ThemeProvider;
