import { VFC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowIcon } from 'src/shared/assets/icons';
import { Button } from 'src/shared/components';
import { ROUTES } from 'src/shared/constants';

import styles from './return-button.module.scss';
import { ReturnButtonProps } from './types';

const ReturnButton: VFC<ReturnButtonProps> = ({ to = ROUTES.root }) => {
  const navigate = useNavigate();

  return (
    <Button className={styles.button} onClick={() => navigate(to)}>
      <ArrowIcon className={styles.buttonIcon} />
    </Button>
  );
};

export default ReturnButton;
