import type { VFC } from 'react';

import styles from './empty.module.scss';
import type { EmptyProps } from './types';

const Empty: VFC<EmptyProps> = ({ extra, ...rest }) => {
  return (
    <div className={styles.emptyWrapper}>
      <div className={styles.empty} {...rest}>
        {extra && <div className={styles.emptyExtra}>{extra}</div>}
        <p>No data</p>
      </div>
    </div>
  );
};

export default Empty;
