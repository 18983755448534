import { useEffect, useState, VFC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getUpdateDetails, markUpdateWatched } from 'src/api/updates-api/updates-api';
import { ReturnButton } from 'src/features';
import { Empty, Loader, Modal } from 'src/shared/components';
import { postCloseMessage } from 'src/shared/lib/message';
import { useAppDispatch } from 'src/store';
import { updatesActions, updatesSelectors } from 'src/store/updates';

import Update from './update';
import styles from './update-view.module.scss';

const UpdateViewPage: VFC = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();

  const { update, isWatched } = useSelector(updatesSelectors.getUpdate(id));

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!update && !isLoading) {
      const idNumber = Number(id);

      setIsLoading(true);

      if (isWatched) {
        getUpdateDetails(idNumber)
          .then(({ data }) => {
            dispatch(updatesActions.setUpdateWatched(data));
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        getUpdateDetails(idNumber)
          .then(({ data }) => {
            dispatch(updatesActions.watchUpdate(data));
            markUpdateWatched(idNumber);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [dispatch, id, isLoading, isWatched, update]);

  return (
    <main>
      {update ? (
        <Update update={update} />
      ) : (
        <Modal
          className={styles.update}
          header={
            <div className={styles.updateHeader}>
              <ReturnButton />
            </div>
          }
          handleClose={postCloseMessage}
        >
          {isLoading ? <Loader /> : <Empty />}
        </Modal>
      )}
    </main>
  );
};

export default UpdateViewPage;
