import type { VFC } from 'react';

import { List } from 'src/shared/components';

import type { UpdatesListProps } from './types';
import UpdatesListItem from './updates-list-item';

const UpdatesList: VFC<UpdatesListProps> = ({ updates }) => {
  return (
    <List>
      {updates.map((update) => (
        <UpdatesListItem key={update.id} {...update} />
      ))}
    </List>
  );
};

export default UpdatesList;
