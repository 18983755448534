export const getItemFromStorage = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) || '');
  } catch {
    return null;
  }
};

/**
 * @returns {boolean} - If the value has been set, returns `true`, otherwise - `false`.
 */
export const setItemToStorage = (key: string, item: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(item));
    return true;
  } catch {
    return false;
  }
};
