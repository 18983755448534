import { VFC } from 'react';

import { LoaderImage } from 'src/shared/assets/images';

import styles from './loader.module.scss';

const Loader: VFC = () => {
  return (
    <div className={styles.loaderWrapper}>
      <p className={styles.loader}>
        <LoaderImage />
      </p>
    </div>
  );
};

export default Loader;
