import clsx from 'clsx';
import type { VFC } from 'react';

import type { UpdateDetailsProps } from './types';
import styles from './update-details.module.scss';

const UpdateDetails: VFC<UpdateDetailsProps> = ({ slide, className, ...rest }) => {
  return (
    <div className={clsx(styles.detailsSlideInner, className)} {...rest}>
      <h4>{slide?.title}</h4>
      <p>{slide?.text}</p>
      {slide?.image && <img src={slide.image} alt={slide.title} className={styles.detailsSlideImage} />}
    </div>
  );
};

export default UpdateDetails;
