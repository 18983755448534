import type { VFC } from 'react';

import { Router } from 'src/router';

import 'src/styles/index.scss';

const App: VFC = () => {
  return <Router />;
};

export default App;
