import clsx from 'clsx';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { List } from 'src/shared/components';
import { ROUTES } from 'src/shared/constants';
import { getTextDate } from 'src/shared/lib/date';
import type { MarkedUpdate } from 'src/store/updates/types';

import styles from './update-list-item.module.scss';

const UpdatesListItem: FC<MarkedUpdate> = ({ id, name, publication_date, isWatched }) => {
  const navigate = useNavigate();

  return (
    <List.Item
      className={clsx(styles.update, !isWatched && styles.updateNotWatched)}
      onClick={() => navigate(`${ROUTES.root}${id}`)}
    >
      <p className={styles.updateDate}>{getTextDate(publication_date)}</p>
      <p className={styles.updateTitle}>{name}</p>
    </List.Item>
  );
};

export default UpdatesListItem;
