import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';

import styles from './list-item.module.scss';

const ListItem: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className, ...rest }) => {
  return (
    <div className={clsx(styles.listItem, className)} {...rest}>
      <div className={styles.listItemInner}>{children}</div>
    </div>
  );
};

export default ListItem;
