import type { AdvancedUpdate } from 'src/api/updates-api/types';
import type { RootState } from 'src/store';

import type { MarkedUpdate } from './types';

export const getUpdates = (state: RootState): MarkedUpdate[] | null => {
  if (!state.updates.allUpdates) {
    return null;
  }

  const { allUpdates, notWatchedUpdates } = state.updates;

  const notWatchedUpdatesIds = notWatchedUpdates?.map(({ id }) => id);

  return allUpdates?.map((update) => ({ ...update, isWatched: !notWatchedUpdatesIds?.includes(update.id) }));
};

export const getUpdateIsWatched =
  (id?: string) =>
  (state: RootState): boolean => {
    const updates = getUpdates(state);

    return updates?.find((update) => Number(id) === update.id)?.isWatched || false;
  };

export const getUpdate =
  (id?: string) =>
  (state: RootState): { update: AdvancedUpdate | undefined; isWatched: boolean } => {
    const { watchedUpdates } = state.updates;

    const update = watchedUpdates?.find((update) => update.id === Number(id));

    return {
      update,
      isWatched: Boolean(update) || getUpdateIsWatched(id)(state),
    };
  };
