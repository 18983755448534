import type { AxiosResponse } from 'axios';

import { agent } from 'src/api/agent';
import { PRODUCT_STORAGE_KEY } from 'src/features/storage-provider/constants';
import { getItemFromStorage } from 'src/shared/lib/storage';

import type { AdvancedUpdate, UpdateList } from './types';

export const getUpdatesList = (): Promise<AxiosResponse<UpdateList>> => {
  const product = getItemFromStorage(PRODUCT_STORAGE_KEY);

  return agent.get(`/updates/${product}`);
};

export const getUpdateDetails = (id: number): Promise<AxiosResponse<AdvancedUpdate>> => {
  const product = getItemFromStorage(PRODUCT_STORAGE_KEY);

  return agent.get(`/updates/${product}/${id}`);
};

export const markUpdateWatched = (id: number): Promise<AxiosResponse<AdvancedUpdate>> => {
  const product = getItemFromStorage(PRODUCT_STORAGE_KEY);

  return agent.post(`/updates/${product}/${id}/mark_watched`);
};
