import { useCallback, useState, VFC } from 'react';

import { AdvancedUpdate } from '../../api/updates-api/types';
import { ReturnButton, UpdateDetails } from '../../features';
import { SliderModal } from '../../shared/components';
import { getTextDate } from '../../shared/lib/date';
import { postCloseMessage } from '../../shared/lib/message';

import styles from './update-view.module.scss';

interface Props {
  update: AdvancedUpdate;
}

const Update: VFC<Props> = ({ update }) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const nextSlide = useCallback(() => {
    if (update) {
      setCurrentSlide((actual) => (actual >= update.slides.length ? actual : actual + 1));
    }
  }, [update]);

  const previousSlide = useCallback(() => {
    if (update) {
      setCurrentSlide((actual) => (actual <= 0 ? actual : actual - 1));
    }
  }, [update]);

  return (
    <SliderModal
      className={styles.update}
      header={
        <div className={styles.updateHeader}>
          <ReturnButton />
          <h2 className={styles.updateHeaderTitle}>{update.name}</h2>
        </div>
      }
      currentSlide={currentSlide}
      slidesNumber={update.slides.length}
      leftHandler={previousSlide}
      rightHandler={nextSlide}
      handleClose={postCloseMessage}
    >
      <div className={styles.updateSlidesContainer}>
        <p className={styles.updateDate}>{getTextDate(update.publication_date)}</p>
        <h3 className={styles.updateTitle}>{update.name}</h3>

        <UpdateDetails slide={update.slides[currentSlide]} />
      </div>
    </SliderModal>
  );
};

export default Update;
