import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ACCESS_TOKEN_STORAGE_KEY } from 'src/shared/constants';
import { setItemToStorage } from 'src/shared/lib/storage';

import { setAccessToken } from '../../shared/lib';

import { PRODUCT_STORAGE_KEY } from './constants';

const StorageProvider: FC = ({ children }) => {
  const [searchParams] = useSearchParams();

  const product = searchParams.get(PRODUCT_STORAGE_KEY);

  if (product) {
    setItemToStorage(PRODUCT_STORAGE_KEY, product);
  }

  const accessToken = searchParams.get(ACCESS_TOKEN_STORAGE_KEY);

  if (accessToken) {
    setAccessToken(accessToken);
  }

  // TODO: add language capture
  /*
    Initialize i18n here
  */

  return <>{children}</>;
};

export default StorageProvider;
