import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AdvancedUpdate } from 'src/api/updates-api/types';

import type { Updates, UpdatesState } from './types';

const initialState: UpdatesState = {
  allUpdates: null,
  notWatchedUpdates: null,
  watchedUpdates: null,
};

const updates = createSlice({
  name: 'updates',
  initialState,
  reducers: {
    setUpdates: (state, action: PayloadAction<Updates>) => ({
      ...state,
      ...action.payload,
    }),
    setUpdateWatched: (state, action: PayloadAction<AdvancedUpdate>) => {
      state.notWatchedUpdates = state.notWatchedUpdates?.filter((update) => update.id !== action.payload.id) || null;

      if (!state.watchedUpdates) {
        state.watchedUpdates = [action.payload];
      } else {
        state.watchedUpdates.push(action.payload);
      }
    },
  },
});

export default updates;
