import clsx from 'clsx';
import type { FC } from 'react';

import { ArrowIcon, CrossIcon } from 'src/shared/assets/icons';
import { Button } from 'src/shared/components';

import styles from './slider-modal.module.scss';
import type { ModalSliderProps } from './types';

const SliderModal: FC<ModalSliderProps> = ({
  children,
  className,
  contentClassName,
  header,
  currentSlide,
  slidesNumber,
  leftHandler,
  rightHandler,
  handleClose,
  ...rest
}) => {
  const shouldRenderRightArrow = (slidesNumber && currentSlide !== slidesNumber - 1) || !slidesNumber;

  return (
    <div className={styles.modalContainer}>
      <div className={clsx(styles.modalInner, className)} {...rest}>
        <div className={styles.modalHeader}>
          {header}
          <Button className={styles.modalCloseButton} onClick={handleClose}>
            <CrossIcon className={styles.modalCloseIcon} />
          </Button>
        </div>

        <div className={clsx(styles.modalContentWrapper, contentClassName)}>{children}</div>

        {currentSlide !== 0 && (
          <div className={styles.modalSlideButtonWrapper}>
            <Button className={clsx(styles.modalSlideButton, styles.modalSlideButtonLeft)} onClick={leftHandler}>
              <ArrowIcon className={styles.modalSlideButtonIcon} />
            </Button>
          </div>
        )}

        {shouldRenderRightArrow && (
          <div className={clsx(styles.modalSlideButtonWrapper, styles.modalSlideButtonWrapperRight)}>
            <Button className={styles.modalSlideButton} onClick={rightHandler}>
              <ArrowIcon className={styles.modalSlideButtonIcon} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SliderModal;
