import { createAsyncThunk } from '@reduxjs/toolkit';

import type { AdvancedUpdate } from 'src/api/updates-api/types';
import { Messages } from 'src/shared/constants/messages';
import { postMessageToParent } from 'src/shared/lib/message';
import type { RootState } from 'src/store';
import { updatesActions } from 'src/store/updates';

export const watchUpdate = createAsyncThunk<unknown, AdvancedUpdate, { state: RootState }>(
  'updates/watchUpdate',
  async (update, { dispatch, getState }) => {
    dispatch(updatesActions.setUpdateWatched(update));

    const state = getState();

    if (!state.updates.notWatchedUpdates?.length) {
      postMessageToParent(Messages.allWatched);
    }

    return update;
  }
);
