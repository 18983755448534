import clsx from 'clsx';
import type { FC } from 'react';

import { CrossIcon } from 'src/shared/assets/icons';
import { Button } from 'src/shared/components';

import styles from './modal.module.scss';
import type { ModalProps } from './types';

const Modal: FC<ModalProps> = ({ children, className, contentClassName, header, handleClose, ...rest }) => {
  return (
    <div className={styles.modalContainer}>
      <div className={clsx(styles.modalInner, className)} {...rest}>
        <div className={styles.modalHeader}>
          {header}
          <Button className={styles.modalCloseButton} onClick={handleClose}>
            <CrossIcon className={styles.modalCloseIcon} />
          </Button>
        </div>

        <div className={clsx(styles.modalContentWrapper, contentClassName)}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
